import * as React from "react"
import {Card, CardBody, CardHeader, Carousel, Col, Container, Row} from "react-bootstrap";
import {Aperture, Calendar, Clock, FileText, FolderPlus, Lock, Send, Shield, ThumbsUp, UserX} from "react-feather";
import {StaticImage} from "gatsby-plugin-image";
import Contact from "../components/contact";
import Layout from "../components/layout";
import Seo from "../components/seo";

// data
const features = [
    {
        title: 'Mitteilungen',
        icon: <FileText/>,
        text: 'Halten Sie die Eltern über Neuigkeiten rund um die Schule stets auf dem neuesten Stand.'
    },
    {
        title: 'Dokumente',
        icon: <FolderPlus/>,
        text: 'Zustimmungspflichtig oder nicht, Sie können Dokumente hochladen und an die Eltern adressieren.'
    },
    {
        title: 'Termine',
        icon: <Calendar/>,
        text: 'Informieren Sie die Eltern über die anstehenden Termine, Events und Ausflüge.'
    },
    {
        title: 'Abwesenheit',
        icon: <UserX/>,
        text: 'Kein Anruf mehr notwendig. Bequem und einfach können Eltern die Schüler als abwesend melden.'
    },
];

const facts = [
    {
        title: 'Datenschutz',
        icon: <Shield/>,
        text: 'Keine Messenger Apps mehr um die Informationen an den Eltern zu schicken. Unsere Infrastruktur erfüllt die Anforderungen der DSGVO. Genießen Sie volle Transparenz und Gewissheit, über Ihre Daten.'
    },
    {
        title: 'Individuell',
        icon: <Aperture/>,
        text: 'Sie können die Farbenwelt und das Logo Ihrer Schule verwenden. Die App wird unter dem Namen der Schule in den Stores veröffentlicht.'
    },
    {
        title: 'Push & E-Mail',
        icon: <Send/>,
        text: 'Die Eltern sind stets uptodate. Sie werden über das Einstellen neuer Inhalte benachrichtigt. Über die Einstellungen lassen sich die Benachrichtigungen an- und ausschalten.'
    },
    {
        title: 'Zugriffskontrolle',
        icon: <Lock/>,
        text: 'Der Zugang zu den Inhalten ist anhand der Klassenzuordnung eingeschränkt. Dadurch ist sichergestellt, dass die Eltern nur auf die Inhalte zugreifen können, die an sie adressiert sind.'
    },
    {
        title: 'Intuitiv',
        icon: <ThumbsUp/>,
        text: 'Einfach zu bedienen und leicht zu verstehen. Das sind unsere Prinzipien bei der Umsetzung der User Interface des Portals und der App, um die Akzeptanz unserer Produkte zu gewährleisten.'
    },
    {
        title: 'Quick start',
        icon: <Clock/>,
        text: 'Sie möchten loslegen? Wir auch. Nehmen Sie Kontakt zu uns auf und wir besprechen die nächsten Schritte, damit Sie schnellstmöglich mit den Eltern die digitale Kommunikation starten können.'
    },
]

const pricingList = [
    {
        title: 'Bis 100 Schüler',
        color: 'orange',
        price: '1 €',
        period: 'monatlich'
    },
    {
        title: 'Ab 101 bis 250 Schüler',
        price: '0,80 €',
        color: 'lila',
        period: 'monatlich'
    },
    {
        title: 'Ab 251 bis 400 Schüler',
        price: '0,70 €',
        color: 'blue',
        period: 'monatlich'
    }
]

// markup
const IndexPage = () => {
    return <Layout>
        <Seo title={'Mobile App und Webportal für Ihre Schule'} meta={[{name: 'robots', content: 'index'}]}/>
        <Container>
            <div className="jumbotron py-5 mb-4">
                <Row>
                    <Col xs={12} lg={6} className={'mb-5'}>
                        <h1 className="display-4">Mit Schuljournal zur papierlosen Kommunikation</h1>
                        <p className="fs-4 fw-light">Wir digitalisieren die Kommunikation zwischen Schule und Eltern</p>
                    </Col>
                    <Col xs={12} lg={6} className={'hero-image-container'}>
                        <StaticImage src="../images/mySchool-hero.png" alt="Schuljournal Demo" quality={100}/>
                    </Col>
                </Row>
            </div>
        </Container>
        <Container className={'pt-2 pb-5'}>
            <Row>
                <Col>
                    <h2 className={"text-center display-5 my-5"}>
                        Ein Überblick über alle Features
                    </h2>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={4}>
                {features.map(feature => (
                    <Col key={feature.title} className={"align-items-stretch d-flex mb-5"}>
                        <Card className="feature icon-container bg-light border-0 text-center">
                            <Card.Body>
                                <div className={"icon"}>{feature.icon}</div>
                                <h3>{feature.title}</h3>
                                <Card.Text className={"fs-6"}>{feature.text}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))
                }
            </Row>
        </Container>
        <Container fluid={true} className={"bg-secondary bg-gradient text-white mt-5 py-5"}>
            <h2 className="text-center display-5 my-5">Eine App für Eltern und Lehrkräfte</h2>

            <div className="d-md-none">
                <Carousel>
                    <Carousel.Item>
                        <div className="text-center">
                            <StaticImage src="../images/app_screens_home.png" alt="Schuljournal App Home"
                                         quality={100}/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="text-center">
                            <StaticImage src="../images/app_screens_news.png" alt="Schuljournal App Mitteilungen"
                                         quality={100}/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="text-center">
                            <StaticImage src="../images/app_screens_docs.png" alt="Schuljournal App Dokumente"
                                         quality={100}/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="text-center">
                            <StaticImage src="../images/app_screens_calendar.png" alt="Schuljournal App Kalender"
                                         quality={100}/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="text-center">
                            <StaticImage src="../images/app_screens_absence.png" alt="Schuljournal App Abwesenheit"
                                         quality={100}/>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <StaticImage src="../images/app_screens_mobile.png" alt="Schuljournal App" quality={100}/>
            </div>
            <div className="d-none d-lg-block">
                <StaticImage src="../images/app_screens.png" alt="Schuljournal App" quality={100}/>
            </div>
        </Container>
        <Container className={"py-5"}>
            <h2 className="text-center display-5 my-5">Das macht uns aus</h2>
            <Row xs={1} lg={3}>
                {facts.map(fact => (
                    <Col key={fact.title} className={"align-items-stretch d-flex mb-4 fact icon-container"}>
                        <Row>
                            <Col xs={"auto"} className={''}>
                                <div className={"icon"}>{fact.icon}</div>
                            </Col>
                            <Col>
                                <h4>{fact.title}</h4>
                                <p>{fact.text}</p>
                            </Col>
                        </Row>
                    </Col>
                ))
                }
            </Row>
        </Container>
        <Container className={"py-5"}>
            <h2 className="text-center display-5 my-5">Transparente Preise</h2>
            <Row xs={1} lg={3}>
                {pricingList.map(price => (
                    <Col key={price.title} className={"align-items-stretch d-flex mb-4"}>
                        <div className={'w-100 text-center px-3'}>
                            <Card className={'mb-4 shadow-sm w-100 text-center pricing-card'}>
                                <CardHeader className={price.color + ' display-4 px-5'}>{price.price}</CardHeader>
                                <CardBody>
                                    <h5>{price.title}</h5>
                                    <p>Monatlich <br/>Pro Kind</p>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                ))
                }
            </Row>
        </Container>
        <div id="contact" className={"bg-light bg-gradient"}>
            <Container className={"py-5"}>
                <h2 className="text-center display-5 my-5">Neugierig?<br/> Kontaktieren Sie uns!</h2>
                <Row className={'pb-5'}>
                    <Col xs={12} lg={10} xl={8} className={'mx-auto'}>
                        <Contact/>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
}

export default IndexPage
