import React, {useState} from "react"
import {Link} from "gatsby"
import {useInput} from '../hooks/use-input';
import {Card, Col, FloatingLabel, Form, Row} from "react-bootstrap";

const Contact = () => {
    const [formErrors, setFormErrors] = useState({
        firstName: false,
        lastName: false,
        email: false
    });

    const [formProcessingState, setFormProcessingState] = useState({
        showSuccess: false,
        showError: false,
        isLoading: false
    });

    const [gotcha] = useState(false);
    const {value: firstName, bind: bindFirstName} = useInput('');
    const {value: lastName, bind: bindLastName} = useInput('');
    const {value: email, bind: bindEmail} = useInput('');
    const {value: phone, bind: bindPhone} = useInput('');
    const {value: message, bind: bindMessage} = useInput('');

    const isValidEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const validateAndSubmitForm = () => {
        setFormErrors(() => {

            const formValidation = {
                firstName: firstName.trim() === '',
                lastName: lastName.trim() === '',
                email: !isValidEmail(email)
            };


            const formIsValid = !(formValidation.firstName || formValidation.lastName
                || formValidation.email || formValidation.phone);

            if (formIsValid) {
                submitForm();
                return {};
            }

            return formValidation;
        });
    };

    const submitForm = () => {
        const data = {firstName, lastName, phone, email, message, gotcha};

        setFormProcessingState({
            showSuccess: false,
            showError: false,
            isLoading: true
        });
        fetch(
            process.env.CONTACT_API_URL,
            {
                body: JSON.stringify(data),
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                setFormProcessingState({
                    showSuccess: data.success,
                    showError: !data.success,
                    isLoading: false
                });
            })
            .catch(() => {
                setFormProcessingState({
                    showSuccess: false,
                    showError: true,
                    isLoading: false
                });
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        validateAndSubmitForm();
        return false;
    };

    return (
        <Card className={"shadow"}>
            <Card.Body>
                {!formProcessingState.showSuccess && !formProcessingState.showError &&
                <Form onSubmit={handleSubmit} noValidate="novalidate" className={'p-3'}>
                    <Row xs={1} md={2}>
                        <Col className={'mb-4'}>
                            <FloatingLabel controlId="first-name" label="Vorname*">
                                <Form.Control type={'text'} placeholder={'Vorname'} isInvalid={formErrors.firstName}  {...bindFirstName}/>
                            </FloatingLabel>
                        </Col>
                        <Col className={'mb-4'}>
                            <FloatingLabel controlId="last-name" label="Nachname*">
                                <Form.Control type={'text'} placeholder={'Nachname'} isInvalid={formErrors.lastName}  {...bindLastName}/>
                            </FloatingLabel>
                        </Col>
                        <Col className={'mb-4'}>
                            <FloatingLabel controlId="email" label="E-Mail*">
                                <Form.Control type={'email'} placeholder={'E-Mail'} isInvalid={formErrors.email}  {...bindEmail}/>
                            </FloatingLabel>
                        </Col>
                        <Col className={'mb-4'}>
                            <FloatingLabel controlId="phone" label="Telefon">
                                <Form.Control type={'phone'} placeholder={'Telefon'} {...bindPhone}/>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <div className="mb-4">
                        <FloatingLabel controlId="message" label="Ihre Nachricht">
                            <Form.Control as={'textarea'} style={{height: '200px'}} placeholder={'Ihre Nachricht'} {...bindMessage}/>
                        </FloatingLabel>
                    </div>
                    <p className="left">Mit Absenden des Formulars bestätigen Sie, die <Link
                        to={'/datenschutz-erklaerung'}>Datenschutzerklärung</Link> gelesen zu haben.</p>

                    <div className="mt-3 mb-4 float-end">
                        {formProcessingState.isLoading &&
                        <div className="spinner-grow bg-primary" style={{width: '3rem', height: '3rem'}} role="status"/>
                        }
                        {!formProcessingState.isLoading &&
                        <button className="btn btn-primary btn-lg">
                            Abschicken
                        </button>
                        }
                    </div>
                </Form>
                }
                {formProcessingState.showSuccess &&
                <div className={'p-3 text-center'}>
                    <h3 className="text-success display-5">Vielen Dank für Ihre Nachricht</h3>
                    <p>Wir werden in Kürze Ihre Anfrage bearbeiten.</p>
                </div>
                }
                {formProcessingState.showError &&
                <div className="col s12 center">
                    <h3 className="text-danger display-5">Ein Fehler ist aufgetreten</h3>
                    <p>Versuchen Sie es zu einem späteren Zeitpunkt erneut.</p>
                </div>
                }
            </Card.Body>
        </Card>
    );
}

export default Contact;